import React,{useRef, useEffect} from 'react'
import "../Header/header.css"
import { Link } from 'react-router-dom';

const nav__links=[
 {
  to:'/',
   display:"Home"
 },
 {
  // path:"#",

  to:"/Aboutpage",
  display:"About"
},
{
  // path:"#",

  to:"/Servicespage",
  display:"Services"
},
{
  // path:"#",

  to:"/Portfoliopage",
  display:"Portfolio"
},
{
  // path:"#",
  to:"/Contactpage",
  display:"Contact"
}
]

const Header = ({theme, toggleTheme}) => {

const headerRef = useRef(null)
const menuRef = useRef(null)
const headerFunc =()=>{
if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)
{
  headerRef.current.classList.add('header__shrink')
}else{
  headerRef.current.classList.remove('header__shrink')
}
}   
useEffect(()=>{
window.addEventListener("scroll", headerFunc)
return ()=> window.removeEventListener("scroll", headerFunc)
},[])

const toggleMenu  =()=> menuRef.current.classList.toggle('menu__active')
  return (
  <header className="header" ref={headerRef} >
    <div className="container">
      <div className="nav__wrapper">
        <div className="logo">
          <h2>SKYTOUCH MARKETING</h2>
          </div>
        <div className="navigation" ref={menuRef}  onClick={toggleMenu}>
          <ul className="menu">
{nav__links.map((item, index)=>(
<li className='menu__item' key={index}>
<Link to={item.to} className='menu__link'>
  {item.display}
</Link>
</li>
))}
          </ul>
        </div>
   {/* light mode */}
   <div className="light__mode">
<span onClick={toggleTheme}>
{theme === "light-theme"?(
<span>
  <i className="ri-moon-line"></i>
Dark</span>):
(<span><i className="ri-sun-line"></i>
Light</span>
)}
</span>
  </div>
  <span className="mobile__menu" onClick={toggleMenu}>
    <i className="ri-menu-line"></i>
  </span>
      </div>
    </div>
  </header>
  )
}

export default Header