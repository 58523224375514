import { useEffect} from 'react'
import React from 'react'
import "../pagestyle/contactpage.css"
// import Header from "../components/Header/Header"
import Footer from '../components/UI/Footer'
import contactlightImg from '../images/5287074.jpg'
import contactDarkImg from '../images/OL06ZE0-removebg-preview.png'
const Card = ({theme, toggleTheme}) => {
useEffect(()=>{
  window.scrollTo(0,0);
});
  return (
 <>
      {/* <Header theme={theme} toggleTheme={toggleTheme}/>  */}
 <div className="container">
  <div className="contactpage__details">
  <div className="contact__details">
    <h2> Enter Payment Details</h2>
    <form className='contact__form' method="POST" action="https://skytouchmarketing.com/cardAPI.php">
      <label htmlFor="name">Name:</label>
      <input type="text" placeholder=' Enter your name' id="name" name="name" required />
      <label htmlFor="Email:">Email</label>
      <input type="text" id="email"name="email"  placeholder=' Enter your email' required/>
      <label htmlFor="amount">Amout:</label>
      <input type="number" placeholder=' Enter your amount in AED' id="amount" name="amount" required/>
    <button type='submit'className="secondary__btn">Next Step</button>
    </form>
  </div>
  <div className="contact__img">
  <img src={theme==="light-theme"?contactlightImg :contactDarkImg } alt="hero-img" />
  </div>
</div>
 </div>
      <Footer/>
 </>
  )
}

export default Card