import React from 'react'
import '../../styles/hero.css'
// import video2 from'../../images/video.png'
import { Link } from 'react-router-dom'
import heroDarkImg from "../../images/hero-img.png"
import lightImg from "../../images/light-hero-bg.jpg"
const Hero = ({theme, toggleTheme}) => {
  return (
    <section className="hero__section">
        <div className="container">
            <div className="hero__wrapper">
                <div className="hero__content">
           <div>
                        <h2>We're Creating Perfect</h2>
                        <h2>Digital Product To</h2>
                        <h2 className="highlight">Promote Your Brand</h2>
           </div>     
                    <p className="description">
                    "Sky Touch Marketing: Bridging tech and marketing for superior results. With cutting-edge strategies, SEO prowess, and global-local finesse, we propel your brand skyward. Join us and revolutionize your digital presence today."      
       </p>
            <div className="hero__btn">  
            <button className="secondary__btn"><Link to="/Aboutpage">
            Discover More
</Link></button>
            </div>
                </div>
                <div className="hero__img">
                   
                    <img src={theme==="light-theme"?lightImg :heroDarkImg} alt="hero-img" />
  
                       </div>
</div>
            </div>
    </section>
  )
}

export default Hero