import React from 'react'
import '../../styles/about.css'
import aboutImg from '../../images/about-us.jpg'
const chooseData = [
    {
        icon:'ri-wifi-line',
        title:'First working process',
desc:"Our first working process is the foundation of success. We begin by thoroughly understanding your objectives and requirements, ensuring that we're aligned with your vision."
    },
    {
        icon:'ri-team-line',
        title:'Dedicated team',
        desc:"At SkyTouch Marketing, we pride ourselves on our dedicated team of professionals. Each member is committed to your project's success. With expertise in their respective fields."
    },
    {
        icon:'ri-customer-service-2-line',
        title:'24/7 Hours support',
        desc:"We understand that questions and issues can arise at any hour. That's why our 24/7 support team is here for you around the clock. Whether it's day or night, we're committed."

    },

]
const About = () => {
  return (
    <section className="about">
        <div className="container">
            <div className="about__wrapper">
                <div className="about__content">
                    <h6 className="subtitle">Why choose us</h6>
                    <h2>Specialist in aviding clients on</h2>
                    <h2 className="highlight">Financial challenges</h2>
                <p className="description about__content-desc"> Benefit from our seasoned professionals and industry knowledge.We tailor services to match your unique needs precisely.Count on us for on-time delivery and transparent communication.</p>
<div className="choose__item-wrapper">
 {
chooseData.map((item, index)=>(
    <div className="choose__us-item" key={index}>
<span className="choose__us-icon">
<i className={item.icon}></i>
</span>
<div>
<h4 className="choose__us-title">{item.title}</h4>
<p className="description">{item.desc}</p>
</div>
</div>
))
 }   
</div>
</div>
<div className="about__img">
<img src={aboutImg} alt="about" />
</div>
</div>
</div>
</section>
  )
}
export default About