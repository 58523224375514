import React from 'react';
import { useEffect } from 'react';
const ProjectCard = ({ project }) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  });
  return (
    <div className="project-card">
      <img src={project.image} alt={project.title} />
      <h3>{project.name}</h3>
      <p>{project.detail}</p>
      {/* <button className="secondary__btn">View Pricing</button> */}

    </div>
  );
};

export default ProjectCard;