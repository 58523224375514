import React from 'react'
import '../../styles/services.css'
import { Link } from 'react-router-dom'

const servicesData =[
    {
        icon:'ri-code-box-line',
        title:'Tech Services',
        desc:"Choose us for tech services that transform your challenges into opportunities. Our expertise powers your growth."
    },
    { 
        icon: "ri-store-2-line",
        title:'Marketing services',
        desc:"Unlock your business's online potential with our expert digital marketing services. We tailor strategies to your goals, delivering tangible results"
    },
    {
        icon:"ri-movie-2-line",
        title:'production services',
        desc:"Let us bring your ideas to life through quality video production. Elevate your brand, engage audience, and achieve your goals with our expertise."
}
]

const Services = () => {

  return (
    <section id="service">
        <div className="container">
            <div className="services__top-content">
                <h6 className="subtitle">Our Services</h6>
                <h2>Save time managing your bussiness with</h2>
                <h2 className="highlight">our best services</h2>
            </div>
            <div className="services__item-wrapper">
           {servicesData.map((item,index)=>(
               <div className="services__item" key={index}>
                    <span className="service__icon">
                        <i className={item.icon}></i>
                    </span>
                 <h3 className="service__title">{item.title}</h3>
                 <p className="description">{item.desc}</p>   
<button className="services__btn"><Link to="/Servicespage">learn more</Link></button>
                </div>
           ))}
            </div>
        </div>
    </section>
  )
}

export default Services