 
import Header from './components/Header/Header'
import Hero from './components/UI/Hero' 
import Counter from './components/UI/Counter' 
import Services from './components/UI/Services' 
import About from './components/UI/About' 
import Testimonials from './components/UI/Testimonials' 
import Newsletter from './components/UI/Newsletter' 
import Footer from './components/UI/Footer' 
import Servicespage from './pages/Servicespage'
import Contactpage from './pages/Contactpage'
import Portfoliopage from './pages/Portfoliopage'
import Aboutpage from './pages/Aboutpage'
import Card from './pages/Card'

import './App.css'
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

function App() {
 
  const [theme, setTheme] = useState('');

  const toggleTheme = () => {
    setTheme(theme === '' ? 'light-theme' : '');
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const HomePage = () => (
    <div>
      <Hero theme={theme} toggleTheme={toggleTheme}/>
      <Counter />
      <About />
      <Services />
      <Newsletter />
      <Testimonials />
      <Footer />
    </div>
  );
   
  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <Routes>
        <Route path="/Aboutpage" element={<Aboutpage theme={theme} toggleTheme={toggleTheme} />} />
        <Route path="/Servicespage" element={<Servicespage theme={theme} toggleTheme={toggleTheme} />} />
        <Route path="/Contactpage" element={<Contactpage theme={theme} toggleTheme={toggleTheme} />} />
        <Route path="/Portfoliopage" element={<Portfoliopage theme={theme} toggleTheme={toggleTheme} />} />
        <Route path="/Card" element={<Card theme={theme} toggleTheme={toggleTheme} />} />

        <Route path="/" element={<HomePage />} />
      </Routes>
    </>
  );
}

export default App;
