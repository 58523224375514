import { useState,useEffect } from 'react'

import React from 'react'
import "../pagestyle/contactpage.css"
// import Header from "../components/Header/Header"
import Footer from '../components/UI/Footer'
import contactlightImg from '../images/5287074.jpg'
import contactDarkImg from '../images/OL06ZE0-removebg-preview.png'
const Contactpage = ({theme, toggleTheme}) => {
  const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [message, setMessage] = useState('');
   const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your code here to handle form submission (e.g., send the data to a server).
    // For this example, we'll just log the values to the console.
    console.log(`Name: ${name}`);
    console.log(`Email: ${email}`);
    console.log(`Message: ${message}`);
  };
  useEffect(()=>{
    window.scrollTo(0,0);
  });
  return (
 <>
      {/* <Header theme={theme} toggleTheme={toggleTheme}/>  */}
 <div className="container">
  <div className="contactpage__details">
  <div className="contact__details">
    <h2>Contact <span>Us</span></h2>
    <form className='contact__form' action="https://skytouchmarketing.com/emailAPI.php" method="POST">
      <label htmlFor="name">Name:</label>
      <input type="text" placeholder=' Enter your name' id="name" name="name" value={name} onChange={handleNameChange} required/>
      <label htmlFor="Email:">Email</label>
      <input type="text" id="email"name="email" value={email} onChange={handleEmailChange} required placeholder=' Enter your email'/>
      <label htmlFor="Message">Message for us</label>
    <textarea placeholder='  Text here' id="message"name="message" value={message} onChange={handleMessageChange} required />
    <button type='submit'className="secondary__btn">Submit</button>
    </form>
  </div>
  <div className="contact__img">
  <img src={theme==="light-theme"?contactlightImg :contactDarkImg } alt="hero-img" />
  </div>
</div>
 </div>
      <Footer/>
 </>
  )
}

export default Contactpage